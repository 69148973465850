import { Link } from 'gatsby'
import React from 'react'
import "../../../styles/AdminPage.scss";
import Navbar from '../../../sections/Navbar/Navbar';
import Footer from '../../../sections/Footer';

function AdminPage() {
    return (
        <>
            <Navbar />
            <div className="AdminHome">
                <span>
                    The <b>#Coachello App</b> empowers emerging leaders to find their growth opportunities and work on them through 1:1 coaching. Think of it as 100% personalised people development embedded into the workplace.
                </span>
                <ul className="link-list">
                    <b>
                        <li><Link to="coachello-to-colleagues">• Coachello to Colleagues</Link></li>
                        <li><Link to="faq-ms-teams">• FAQ MS-Teams</Link></li>
                        <li><Link to="how-the-teamsapp-works">• How the Teams App works</Link></li>
                        <li><Link to="implementation-for-users">• Implementation for Users</Link></li>
                        <li><Link to="microsoft-365-certification">• Microsoft 365 certification</Link></li>
                        <li><Link to="permissions-&-scope">• Permissions and Scope</Link></li>
                    </b>
                </ul>
            </div>
            <Footer />
        </>
    )
}

export default AdminPage